<script>
export default {
  name: "Commissions",
};
</script>

<template>
  <section>
    <div id="sectionComm">
      <h2>Commissions</h2>
      <div>
        <ul>
          <h3>Screenshots :</h3>
          <li>Single or couple existing poses : 7€</li>
          <li>One aditional pose (and five more screenshots) : + 3€</li>
        </ul>
        <ul>
          <h3>Poses :</h3>
          <li>One pose for one character (ten screenshots) : 10€</li>
          <li>Simple pose for aditional character : + 3€</li>
          <li>Complex/NSFW pose for aditional character: + 5€</li>
        </ul>
        <ul>
          <h3>Make Up / Face Paint :</h3>
          <li>Base price (one race/universal): 10€</li>
          <li>Make it bumpy (normal map): + 5€</li>
          <li>Make it shiny (multi map): + 3€</li>
          <li>Adjust to another race : + 3€</li>
        </ul>
        <ul>
          <h3>Body Paint :</h3>
          <li>Simple paint on one body part : 10€</li>
          <li>Complex paint : + 5€</li>
          <li>One more body part : + 3€</li>
          <li>Make it bumpy (normal map): + 5€</li>
          <li>Make it shiny (multi map): + 3€</li>
        </ul>
      </div>
    </div>
  </section>
</template>

<style>
#sectionComm {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: rgba(72, 88, 116, 0.5);
  box-shadow: 0 0 5px 0 rgba(72, 88, 116, 0.5);
  border-radius: 0.5em;
  padding: 1em;
}
#sectionComm ul {
  margin-bottom: 1em;
  max-width: 70vw;
}
#sectionComm li::before {
  content: "✫ ";
  color: darkmagenta;
  text-shadow: 1px 1px 1px black;
}
#sectionComm li {
  list-style: none;
}
</style>
